<template>
  <mobile-screen :header="true" screen-class="gray-bg fluid occupancy-screen">
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="occupancy-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("occupancy", "header", "map") }}
        </div>
        <template v-slot:right v-if="finderSelectedLevelId">
          <router-link
            :to="{
              name: 'r_occupancy-finder-map-filters'
            }"
          >
            <icon class="filter-icon" icon="#cx-app1-filter-16x16" />
          </router-link>
        </template>
      </top-header-menu-wrapper>
    </template>
    <section
      class="map-iframe-wrapper"
      style="display: flex; width: 100%; position: relative; height: 100%"
      v-if="mapUrl"
    >
      <iframe
        ref="mapIframe"
        style="position: absolute; top: 0; left: 0; width: 100%; bottom: 0; border: none; overflow: auto"
        :src="mapUrl"
        frameborder="0"
        width="100%"
        height="100%"
      ></iframe>
    </section>
    <template v-slot:footer>
      <nav class="actions-menu theme-gray"></nav>
    </template>
  </mobile-screen>
  <router-view></router-view>
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState } from "vuex";
import qs from "qs";

export default {
  name: "OccupancyMap",
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  created() {
    if (!this.mapUrl) {
      this.$router.push({
        name: this.backLinkName
      });
    }
    this.$store.commit("global/setIsFullWidthScreen", true, {
      root: true
    });
  },
  computed: {
    ...mapState("occupancy", [
      "mapUrl",
      "finderSelectedLevelId",
      "selectedResourceId",
      "selectedResourceTypeIds",
      "selectedResourceTypeIdsNum"
    ])
  },
  props: {
    backLinkName: {
      type: String,
      default: "r_occupancy-dashboard"
    }
  },
  watch: {
    mapUrl(val) {
      if (!val) {
        this.$router.push({
          name: this.backLinkName
        });
      }
    },
    selectedResourceId(val, oldVal) {
      if (val) {
        this.$refs.mapIframe.contentWindow.postMessage(
          {
            call: "addResource",
            value: val
          },
          "*"
        );
      } else if (oldVal)
        this.$refs.mapIframe.contentWindow.postMessage(
          {
            call: "removeResource",
            value: oldVal
          },
          "*"
        );
    },
    selectedResourceTypeIdsNum() {
      const value = qs.stringify({
        resourceTypeIds: this.selectedResourceTypeIds
      });
      this.$refs.mapIframe.contentWindow.postMessage(
        {
          call: "resourceTypeIdsChanged",
          value: value
        },
        "*"
      );
    }
  },
  beforeUnmount() {
    this.$store.commit("global/setIsFullWidthScreen", false, {
      root: true
    });
  }
};
</script>
